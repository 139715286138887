<template>
  <div>
    <v-card>
      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn
            color="primary"
            dark
            class="mb-2 mr-2"
            @click="deleteAllCustomers"
          >
            Delete All Customers
          </v-btn>
        </v-col>
        <v-col class="d-flex justify-center">
          <v-btn
            color="primary"
            dark
            class="mb-2 mr-2"
            @click="deleteSelected"
          >
            Delete Selected Customers
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="customerList"
        item-key="phone"
        class="elevation-1"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        show-select
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Customer List</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add Customer
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editedItem.firstName"
                          label="First Name"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editedItem.lastName"
                          label="Last Name"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editedItem.address"
                          label="Address"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editedItem.city"
                          label="City"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editedItem.state"
                          label="State"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editedItem.zip"
                          label="Zip Code"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editedItem.phone"
                          label="Phone"
                          :rules="phoneRules"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editedItem.email"
                          label="Email"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editedItem.numberOfServices"
                          label="Number Of Services Yearly"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editedItem.percentDiscount"
                          label="Percent Discount"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editedItem.regularPrice"
                          label="Regular Price Per Service"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-select
                          v-model="selectedDate"
                          :items="deadlines"
                          item-text="title"
                          label="Deadline"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :disabled="disableAddCustomerSubmit"
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDeleteSelected" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to delete <br /> Selected Customers?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDeleteSelected">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteSelectedConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to delete the <br /> selected Customers?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDeleteAll" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to delete all <br /> Customers?</v-card-title>
                <v-card-text>
                    <v-text-field
                      v-model='deleteAllConfirmText'
                      label='Type "delete" to confirm'
                      title='Type "delete" to confirm'
                      :rules='deleteAllConfirmTextRules'
                      required
                    ></v-text-field>
                  </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDeleteAll">Cancel</v-btn>
                  <v-btn :disabled="!(deleteAllConfirmText === 'delete')" color="blue darken-1" text @click="deleteAllConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="getCustomers"
          >
            Reset
          </v-btn>
        </template>
        <template 
          v-slot:item.date="{ item }"
        >
          <!-- <span>{{ new Date(item.date).toLocaleString() }}</span> -->
          <span> {{new Date(item.date).toDateString()}} </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash"

import { DB } from '../firebase/db'
import { mapState } from 'vuex'

export default {
  name: 'CustomerList',
  data() {
    return {
      sortBy: 'date',
      sortDesc: true,
      customerList: [],
      selectedDate: undefined,
      selected: [],
      headers: [
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'firstName',
        },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Address', value: 'address' },
        { text: 'City', value: 'city' },
        { text: 'State', value: 'state' },
        { text: 'Zip', value: 'zip' },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Number Of Services', value: 'numberOfServices' },
        { text: 'Percent Discount', value: 'percentDiscount' },
        { text: 'Regular Price', value: 'regularPrice' },
        { text: 'Monthly Payment Cost', value: 'levelPayMonthlyCost' },
        { text: 'Deadline', value: 'deadline', dataType: "Date" },
        { text: 'Agreement Sent Date', value: 'agreementSentDate' },
        { text: "Date Added", value: "date", dataType: "Date" },
        
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      dialogDeleteAll: false,
      dialogDeleteSelected: false,
      deleteAllConfirmText: '',
      deleteAllConfirmTextRules: [
        v => /delete\b/.test(v) || '"delete" is required',
      ],
      editedIndex: -1,
      phoneRules: [
        v => !!v || "Phone is required",
        v => /[0-9]{10}/.test(v) || 'Phone must be a 10 digit number, no spaces, no non-digits',
      ],
      editedItem: {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        zip: 0,
        phone: 0,
        email: '',
        numberOfServices: 0,
        percentDiscount: 0,
        regularPrice: 0,
        agreementSentDate: 'Not Sent'
      },
      defaultItem: {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        zip: 0,
        phone: 0,
        email: '',
        numberOfServices: 0,
        percentDiscount: 0,
        regularPrice: 0,
        agreementSentDate: 'Not Sent'
      },
    }
  },
  computed: {
    ...mapState(['user']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    showDeleteSelected() {
      return this.selected[0]
    },
    disableAddCustomerSubmit() {
      if (this.editedItem.firstName &&
      this.editedItem.lastName &&
      this.editedItem.address &&
      this.editedItem.city &&
      this.editedItem.state &&
      this.editedItem.zip &&
      this.editedItem.phone &&
      this.editedItem.numberOfServices > 0 &&
      this.editedItem.percentDiscount >= 0 &&
      this.editedItem.regularPrice &&
      this.selectedDate) {
        return false
      } else {
        return true
      }
    },
    deadlines() {
      var twoWeeks = new Date();
      var fourWeeks = new Date();
      var sixWeeks = new Date();
      twoWeeks = new Date(twoWeeks.setDate(twoWeeks.getDate() + 14))
      fourWeeks = new Date(fourWeeks.setDate(fourWeeks.getDate() + 28))
      sixWeeks = new Date(sixWeeks.setDate(sixWeeks.getDate() + 42))

      return ([{
          title: '2 Weeks from Today',
          value: twoWeeks.toDateString()
        },
        {
          title: '4 Weeks from Today',
          value: fourWeeks.toDateString()
        },
        {
          title: '6 Weeks from Today',
          value: sixWeeks.toDateString()
        }])
    },
    levelPayMonthlyCost() {
      return (Math.round(((this.editedItem.regularPrice * this.editedItem.numberOfServices)/12 - ((this.editedItem.percentDiscount * .01) * this.editedItem.regularPrice * this.editedItem.numberOfServices / 12)) * 100)/100).toFixed(2)
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  methods: {
    getCustomers(){
      let docRef = DB.collection('user').doc(this.user.uid)
        docRef.get()
          .then((user) => {
            user = user.data()
            if (!user.customerList) {
              this.customerList = []
            } else {
              this.customerList = user.customerList
            }
          })
    },

    deleteAllCustomers() {
      this.dialogDeleteAll = true
    },

    deleteAllConfirm() {
      if (this.deleteAllConfirmText === 'delete') {
        this.customerList = []
        this.updateFirebase()
        this.closeDelete()
      }
    },

    editItem (item) {
      this.editedIndex = this.customerList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteSelected () {
      let len = this.customerList.length
      _.pullAll(this.customerList, this.selected)

      if (!(len === this.customerList.length)) {
        this.dialogDeleteSelected = true
      }
      
    },

    deleteSelectedConfirm () {
      this.updateFirebase()
      this.closeDelete()
    },

    closeDeleteSelected () {
      this.dialogDeleteSelected = false
    },

    deleteItem (item) {
      this.editedIndex = this.customerList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.customerList.splice(this.editedIndex, 1)
      this.updateFirebase()
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDeleteAll () {
      this.dialogDeleteAll = false
      this.deleteAllConfirmText = ''
    },

    save () {
      if (this.editedIndex > -1) {
        this.editedItem.phone = this.validatePhone(this.editedItem.phone)
        this.editedItem.levelPayMonthlyCost = this.levelPayMonthlyCost
        this.editedItem.deadline = this.selectedDate
        Object.assign(this.customerList[this.editedIndex], this.editedItem)
      } else {
        this.editedItem.phone = this.validatePhone(this.editedItem.phone)
        this.editedItem.levelPayMonthlyCost = this.levelPayMonthlyCost
        this.editedItem.deadline = this.selectedDate
        this.editedItem.date = new Date().getTime()
        this.customerList.push(this.editedItem)
      }
      this.updateShowCompletedButton()
      this.updateFirebase()
      this.close()
    },

    validatePhone(number) {
      if (number.length === 10) {
        return '+1'+_.toString(number)
      } else if (number.length === 11){
        return '+'+_.toString(number)
      } else if (number.length === 12 && number.charAt(0) === '+') {
        return number
      } else {
        return 'Invalid Phone'
      }
    },

    updateShowCompletedButton() {
      this.$emit('updateShowCompletedButton')
    },

    updateFirebase() {
      let customerAccountRef = DB.collection("user").doc(this.user.uid)

      customerAccountRef
        .set({
          customerList: this.customerList
        }, { merge: true })
        .then(() => {
          // console.log('Customer list Saved to Firebase')
          // this.resetData()
          this.reloadCustomerComponent()
          this.getCustomers()
          alert('Customer List Successfully uploaded.')
        })
        .catch((err) => {
          console.error('Error setting Document: ', err)
          // this.resetData()
        })
    },

    reloadCustomerComponent() {
      this.$store.dispatch('iterateKey')
    },
  },
  components: {},
  mounted() {
    this.getCustomers()
  },
}
</script>

<style scoped>

</style>